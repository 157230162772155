import axios from 'axios';
import { AppConfiguration } from 'read-appsettings-json';
import authHeader from '../../Auth/auth-header';
import { IMatch, IMatchPlayer } from '../Models/IMatch';
import { IMatchDataProvider } from './IMatchDataProvider';

export class MatchApiDataProvider implements IMatchDataProvider {
    getMatch(gameId: string): Promise<IMatch[]> {
        return new Promise<IMatch[]>((resolve, reject) => {
            axios.get(AppConfiguration.Setting().ApiUrl + "/api/Match/" + gameId, { headers: authHeader() }).then((res) => {
                if(res.status >= 200 && res.status < 400) {
                    resolve(res.data)   
                }
            });
        })
	}

    setStatus(match: IMatch): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(AppConfiguration.Setting().ApiUrl + "/api/Match/Status", {
                matchId: match.matchId,
                status: match.status
            }, { headers: authHeader() }).then((res) => {
                if(res.status >= 200 && res.status < 400) {
                    resolve(true)   
                }
            });
        })
	}

    setPoints(player: IMatchPlayer[], matchId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(AppConfiguration.Setting().ApiUrl + "/api/Match/" + matchId + "/Points", {
                player
            },
            { headers: authHeader() }).then((res) => {
                if(res.status >= 200 && res.status < 400) {
                    resolve(res.data)   
                }
            });
        })   
    }
}