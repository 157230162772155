import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GameApiDataProvider } from '../../../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../../../DataProvider/GameDataProvider/IGameDataProvider';
import { GameStatusEnum, IGame } from '../../../DataProvider/Models/IGame';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import ConfigurationComp from './Configuration/Configurtation.component';
import DisplayDialog from './Dialogs/DisplayDialog';
import './GamePage.scss';
import RunningComponent from './Running/Running.component';

interface IGamePageProps {}

export default function GamePage(props: IGamePageProps) {
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();

	let { id } = useParams();

	const [game, setGame] = React.useState<IGame>();
	const [showDisplay, setShowDisplay] = React.useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loading, setLoading] = React.useState({
		loadGameData: true,
		loadPlayer: true,
	});

	useEffect(() => {
		loadGame();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadGameData: true,
			}));
		gameDataProvider.getGame(id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function onReload(silent: boolean = true) {
		loadGame(silent);
	}

	function onStart() {
		gameDataProvider.startGame(game?.gameId!).then((values) => {
			onReload();
		});
	}

	function onDisplay() {
      onReload()
		setShowDisplay(!showDisplay);
	}

	const statusBadge = () => {
		switch (game?.status) {
			case GameStatusEnum.Configuration:
				return <span className='badge rounded-pill bg-warning text-dark'>Configuration</span>;
			case GameStatusEnum.Running:
				return <span className='badge rounded-pill bg-success'>Runnig</span>;
			case GameStatusEnum.Ended:
				return <span className='badge rounded-pill bg-danger'>Ended</span>;

			default:
				break;
		}
	};

	return (
		<>
			<HeaderPublic />
			<div className='container'>
				<br />
				<div className='row'>
					<div className='col-md-6'>
						<h1>
							{' '}
							{game?.name} | {statusBadge()}{' '}
						</h1>
					</div>
					<div className='col-md-6'>
						{game?.status === GameStatusEnum.Configuration && (
							<button className='float-end btn btn-success btn-lg gamePageManageBtn' onClick={onStart}>
								Start
							</button>
						)}
						<button className='float-end btn btn-light btn-lg gamePageManageBtn' onClick={onDisplay}>
							Display
						</button>
						{showDisplay && <DisplayDialog onClose={() => setShowDisplay(false)} consoleCount={game!.consoleCount} gameId={game!.gameId} />}
					</div>
				</div>
				<br />
				{game?.status === GameStatusEnum.Configuration && <ConfigurationComp id={id!} />}

				{game?.status === GameStatusEnum.Running && <RunningComponent id={id!} />}
			</div>
		</>
	);
}
