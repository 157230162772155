import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Logo from '../../../Assets/logo';
import { GameApiDataProvider } from '../../../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../../../DataProvider/GameDataProvider/IGameDataProvider';
import { IMatchDataProvider } from '../../../DataProvider/MatchDataProvider/IMatchDataProvider';
import { MatchApiDataProvider } from '../../../DataProvider/MatchDataProvider/MatchApiDataProvider';
import { GameStatusEnum, IGame } from '../../../DataProvider/Models/IGame';
import { IMatch, MatchStatusEnum } from '../../../DataProvider/Models/IMatch';
import { IPlayer } from '../../../DataProvider/Models/IPlayer';
import MatchBoxPublic from './Components/MatchBoxPublic';
import Scoreboard from './Components/Scoreboard/Scoreboard';
import './GamePage.scss';

enum TabEnum {
	scoreboard,
	matches,
}

export default function GamePage() {
	let { id } = useParams();

	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();
	const matchDataProvider: IMatchDataProvider = new MatchApiDataProvider();

	const [game, setGame] = React.useState<IGame>();
	const [player, setPlayer] = React.useState<IPlayer[]>();
	const [matches, setMatches] = React.useState<IMatch[]>();
	const [tab, setTab] = React.useState<TabEnum>(TabEnum.scoreboard);
	const [loading, setLoading] = React.useState({
		loadGameData: true,
		loadPlayerData: true,
		loadMatches: true,
	});

	React.useEffect(() => {
		loadGame();
		loadPlayer();
		loadMatches();

		const interval = setInterval(() => {
			onRefresh();
		}, 15000);

		return () => clearInterval(interval);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadGameData: true,
			}));
		gameDataProvider.getGame(id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function loadPlayer(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadPlayerData: true,
			}));
		gameDataProvider.getPlayer(id!).then((value) => {
			setPlayer(value);
			setLoading((prevState) => ({
				...prevState,
				loadPlayerData: false,
			}));
		});
	}

	function loadMatches(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadMatches: true,
			}));
		matchDataProvider.getMatch(id!).then((value) => {
			setMatches(value);
			setLoading((prevState) => ({
				...prevState,
				loadMatches: false,
			}));
		});
	}

	function onRefresh(silent: boolean = true) {
		loadPlayer(silent);
		loadMatches(silent);
      loadGame(silent);
	}

	const className = 'col-md-' + 12 / game?.consoleCount!;

	var ordering: any = {}, // map for efficient lookup of sortIndex
		sortOrder = [MatchStatusEnum.Running, MatchStatusEnum.Preparing, MatchStatusEnum.NotStarted, MatchStatusEnum.Ended];
	for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

	return !loading.loadGameData && !loading.loadPlayerData ? (
		game?.status === GameStatusEnum.Configuration ? (
			<div className='page-wrapper'>
				<div className='center'>
					<Logo className='logo' />
					<span className='small-divider'></span>
					{game.publicRegistration ? (
						<div>
							<h2 className='display-6 text-white'>Das Spiel wird gerade vorbereitet. <br />Melde dich noch zum Mitspielen an!</h2>
							<br />
							<Link className='btn btn-primary btn-lg' to={'/Game/' + id + '/Register'}>
								Anmelden
							</Link>
						</div>
					) : (
						<h2 className='display-6 text-white'>Das Spiel wird gerade vorbereitet.</h2>
					)}
				</div>
			</div>
		) : (
			<div className='page-wrapper'>
				<div className='container'>
               <br />
					<div className='logo-center'>
						<Logo className='logo' />
					</div>
					<br /> <br />
					<h1 style={{ textAlign: 'center' }} className='display-4 text-white'>
						{game?.name}
					</h1>
					<p style={{ textAlign: 'center', fontSize: '1.5em' }} className='display-6 text-white'>
						{game?.location}
					</p>
					<br />
					<div className='row'>
						<div className='col-md-6 text-center'>
							<h1 className={tab === TabEnum.scoreboard ? 'display-6 tab-selector active' : 'display-6 tab-selector'} onClick={() => setTab(TabEnum.scoreboard)}>
								Scoreboard
							</h1>
						</div>
						<div className='col-md-6 text-center'>
							<h1 className={tab === TabEnum.matches ? 'display-6 tab-selector active' : 'display-6 tab-selector'} onClick={() => setTab(TabEnum.matches)}>
								Matches
							</h1>
						</div>
					</div>
					<br />
					{tab === TabEnum.scoreboard && <Scoreboard inverted player={player!} />}
					{tab === TabEnum.matches && (
						<div className='row'>
							{matches &&
								matches
									.sort(function (a, b) {
										return ordering[a.status] - ordering[b.status] || a.matchId.toString().localeCompare(b.matchId);
									})
									.map((x, i) => {
										return <MatchBoxPublic player={player!} match={x} class={className} index={i} playerRound={game!.playerRound!} />;
									})}
						</div>
					)}
					<br />
				</div>
			</div>
		)
	) : (
		<>Loading ...</>
	);
}
