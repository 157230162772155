import React from "react";
import LoginComponent from "./Components/Login.component";

interface ILoginPageProps {
	
}

export default function LoginPage(props: ILoginPageProps) {
	return (
		<section className='vh-100' style={{ backgroundColor: "#508bfc" }}>
			<div className='container py-5 h-100'>
				<div className='row d-flex justify-content-center align-items-center h-100'>
					<div className='col-12 col-md-8 col-lg-6 col-xl-5'>
						<div className='card shadow-2-strong' style={{ borderRadius: "1rem" }}>
							<LoginComponent />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
