import axios from 'axios';
import { AppConfiguration } from 'read-appsettings-json';
import authHeader from '../../Auth/auth-header';
import { IGame } from '../Models/IGame';
import { IUserDataProvider } from './IUserDataProvider';

export class UserApiDataProvider implements IUserDataProvider {
    getGames(): Promise<IGame[]> {
        return new Promise<IGame[]>((resolve, reject) => {
            axios.get(AppConfiguration.Setting().ApiUrl + "/api/Game", { headers: authHeader() }).then((res) => {
                if(res.status >= 200 && res.status < 400) {
                    resolve(res.data)   
                }
            });
        })
	}
}