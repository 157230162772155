import React, { useEffect } from 'react';
import { GameApiDataProvider } from '../../../../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../../../../DataProvider/GameDataProvider/IGameDataProvider';
import { IGame } from '../../../../DataProvider/Models/IGame';
import { IPlayer } from '../../../../DataProvider/Models/IPlayer';
import ConfigurationBox from './Components/ConfigurationBox';
import PlayerBox from './Components/PlayerBox';
import '../GamePage.scss';

interface IConfigurationCompProps {
	id: string;
}

export default function ConfigurationComponent(props: IConfigurationCompProps) {
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();

	const [game, setGame] = React.useState<IGame>();
	const [player, setPlayer] = React.useState<IPlayer[]>();
	const [loading, setLoading] = React.useState({
		loadGameData: true,
		loadPlayer: true,
	});

	useEffect(() => {
		loadGame();
		loadPlayer();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame() {
		setLoading((prevState) => ({
			...prevState,
			loadGameData: true,
		}));
		gameDataProvider.getGame(props.id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function loadPlayer(slient: boolean = false) {
		if (!slient)
			setLoading((prevState) => ({
				...prevState,
				loadPlayer: true,
			}));
		gameDataProvider.getPlayer(props.id!).then((value) => {
			setPlayer(value);
			setLoading((prevState) => ({
				...prevState,
				loadPlayer: false,
			}));
		});
	}

	function updateGame(game: IGame) {
		setGame(game);
		gameDataProvider.updateGame(props.id!, game).then((value) => {
			setGame(value);
		});
	}

	return (
		<>
			{!loading.loadGameData && !loading.loadPlayer ? (
				<div className='row'>
					<div className='col-md-6'>
						<ConfigurationBox game={game!} onUpdate={updateGame} />
					</div>
					<div className='col-md-6'>
						<PlayerBox player={player!} gameId={props.id!} onRefresh={() => loadPlayer(true)} />
					</div>
				</div>
			) : (
				<>Loading...</>
			)}
		</>
	);
}
