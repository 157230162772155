import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IGame } from '../../../../DataProvider/Models/IGame';
import DeleteGameDialog from './Dialogs/DeleteGameDialog';

interface IGameCardProps {
	game: IGame;
	onOpen(id: string): void;
   onDelete(id: string): void;
}

export default function GameCard(props: IGameCardProps) {
   const [showDelete, setShowDelete] = React.useState<boolean>(false);

   function onDelete() {
      setShowDelete(false)
      props.onDelete(props.game.gameId)
   }

	return (
		<div className='col-md-3'>
			<div className='card'>
				<div className='card-body'>
					<h5 className='card-title'>{props.game.name}</h5>
					<h6 className='card-subtitle mb-2 text-muted'>{props.game.location}</h6>
					<p className='card-text'>{props.game.description}</p>
				</div>
				<ul className='list-group list-group-flush'>
					<li className='list-group-item'>Date: {new Date(props.game.startDate).toDateString()}</li>
					<li className='list-group-item'>Created: {new Date(props.game.createDate).toDateString()}</li>
				</ul>
				<div className='card-body btn-block btn-group'>
					<button className='btn btn-primary' style={{ width: '75%' }} onClick={() => props.onOpen(props.game.gameId)}>
						Open
					</button>
					<button className='btn btn-danger' onClick={() => setShowDelete(true)}>
						<FontAwesomeIcon icon={faTrash} />
					</button>
               {showDelete && <DeleteGameDialog game={props.game} onDelete={onDelete} onClose={() => setShowDelete(false)} />}
				</div>
			</div>
		</div>
	);
}
