import React from 'react';
import { IGame } from '../../../../../DataProvider/Models/IGame';

interface IConfigurationBoxProps {
	game: IGame;
	onUpdate(game: IGame): void;
}

export default function ConfigurationBox(props: IConfigurationBoxProps) {
	function handleConsolesChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			consoleCount: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	function handlePlayerRoundChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			playerRound: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	function handleQulificationRoundsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			qualificationRounds: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	function handleSemifinalsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			semifinalsEnabled: event.target.checked,
		};
		props.onUpdate(gameUpdated);
	}

	function handleQuarterfinalsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			quarterfinalsEnabled: event.target.checked,
		};
		props.onUpdate(gameUpdated);
	}

	function handlePublicRegistrationChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			publicRegistration: event.target.checked,
		};
		props.onUpdate(gameUpdated);
	}

	function handlePlayerQuarterfinalsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			playerQuarterfinal: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	function handlePlayerSemifinalsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			playerSemifinal: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	function handlePlayerFinalsChanges(event: any) {
		const gameUpdated: IGame = {
			...props.game,
			playerFinal: event.target.value,
		};
		props.onUpdate(gameUpdated);
	}

	return (
		<>
			<h3>Configuration</h3>
			<hr />
			<form>
				<div className='mb-3 row'>
					<label htmlFor='gameNameInput' className='col-sm-3 col-form-label'>
						Name
					</label>
					<div className='col-sm-9'>
						<input type='text' className='form-control' id='gameNameInput' disabled={true} value={props.game.name} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='gameLocationInput' className='col-sm-3 col-form-label'>
						Location
					</label>
					<div className='col-sm-9'>
						<input type='text' className='form-control' id='gameLocationInput' disabled={true} value={props.game.location} />
					</div>
				</div>

				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Player per console
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.playerRound} onChange={handlePlayerRoundChanges} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label className='col-sm-3 col-form-label' htmlFor='publicRegistrationCheckbox'>
						Public Registration
					</label>
					<div className='col-sm-9'>
						<div className='form-check form-switch'>
							<input className='form-check-input' type='checkbox' id='publicRegistrationCheckbox' checked={props.game.publicRegistration} onChange={handlePublicRegistrationChanges} />
						</div>
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Consoles
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.consoleCount} onChange={handleConsolesChanges} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Qualification Rounds
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.qualificationRounds} onChange={handleQulificationRoundsChanges} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label className='col-sm-3 col-form-label' htmlFor='quarterfinalsCheckbox'>
						Quarterfinals
					</label>
					<div className='col-sm-9'>
						<div className='form-check form-switch'>
							<input className='form-check-input' type='checkbox' id='quarterfinalsCheckbox' checked={props.game.quarterfinalsEnabled} onChange={handleQuarterfinalsChanges} />
						</div>
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Quarterfinals Player
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.playerQuarterfinal} onChange={handlePlayerQuarterfinalsChanges} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label className='col-sm-3 col-form-label' htmlFor='semifinalsCheckbox'>
						Semifinals
					</label>
					<div className='col-sm-9'>
						<div className='form-check form-switch'>
							<input className='form-check-input' type='checkbox' id='semifinalsCheckbox' checked={props.game.semifinalsEnabled} onChange={handleSemifinalsChanges} />
						</div>
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Semifinals Player
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.playerSemifinal} onChange={handlePlayerSemifinalsChanges} />
					</div>
				</div>
				<div className='mb-3 row'>
					<label htmlFor='consoleCountInput' className='col-sm-3 col-form-label'>
						Final Player
					</label>
					<div className='col-sm-9'>
						<input type='number' className='form-control' id='consoleCountInput' value={props.game.playerFinal} onChange={handlePlayerFinalsChanges} />
					</div>
				</div>
			</form>
		</>
	);
}
