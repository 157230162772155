import React from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../../Assets/logo";
import { GameApiDataProvider } from "../../../DataProvider/GameDataProvider/GameApiDataProvider";
import { IGameDataProvider } from "../../../DataProvider/GameDataProvider/IGameDataProvider";
import { IGame } from "../../../DataProvider/Models/IGame";
import { IPlayerDataProvider } from "../../../DataProvider/PlayerDataProvider/IPlayerDataProvider";
import { PlayerApiDataProvider } from "../../../DataProvider/PlayerDataProvider/PlayerApiDataProvider";
import "../../PublicPages.scss";

export default function RegistrationPage() {
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();
	const playerDataProvider: IPlayerDataProvider = new PlayerApiDataProvider();

	let { id } = useParams();

	const [game, setGame] = React.useState<IGame>();
	const [username, setUsername] = React.useState<string>();
	const [created, setCreated] = React.useState<boolean>(localStorage.getItem("playerId") !== null);
	const [loading, setLoading] = React.useState({
		loadGameData: true,
	});
	const [error, setError] = React.useState({
		username: "",
	});

	React.useEffect(() => {
		loadGame();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadGameData: true,
			}));
		gameDataProvider.getGame(id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function handelUsernameChange(event: any) {
		setUsername(event.target.value);
	}

	function onRegister() {
		if (username && username.length >= 3) {
			playerDataProvider
				.createPlayerPublic(id!, username)
				.then((values) => {
					setCreated(true);
                    localStorage.setItem("playerId", values)
				})
				.catch((error) => {
					setError((prevState) => ({
						...prevState,
						username: error,
					}));
				});
		} else {
			setError((prevState) => ({
				...prevState,
				username: "Mindestens 3 Zeichen!",
			}));
		}
	}

	return (
		<div className='page-wrapper'>
			<div className='center'>
				<Logo className='logo' />
				<span className='small-divider'></span>
				{loading.loadGameData ? (
					<p>Loading</p>
				) : created ? (
					<div>
						<h2 className='display-6 text-white'>Erfolgreich angemeldet!</h2>
						<br />
						<Link className='btn btn-primary btn-lg' to={"/Game/" + id}>
							Zurück
						</Link>
					</div>
				) : game?.publicRegistration ? (
					<>
						<div className='mb-3'>
							<input type='text' placeholder='Username' className='form-control' value={username} onChange={handelUsernameChange} />
							{error.username && error.username.length > 0 ? <p className='text-start text-white'>{error.username}</p> : null}
						</div>
						<div className='d-grid gap-2'>
							<button className='btn btn-primary' onClick={onRegister}>
								Anmelden
							</button>
						</div>
					</>
				) : (
					<>
						<h2 className='display-6 text-white'>Die öffentliche Registrierung ist deaktivert! Wende dich an den Spielleiter.</h2>
					</>
				)}
				;
			</div>
		</div>
	);
}
