import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from './AuthService';

interface IAuthContext {
	token: string | null;
	username: string | null;
	onLogin: (username: string, password: string) => void;
	onLogout: () => void;
   onForgotPassword: (username: string) => Promise<boolean>;
   onResetPassword: (username: string, password: string, token: string) => Promise<boolean>;
}

export const AuthContext = React.createContext<any>(undefined);

export default function AuthProvider({ children }: any) {
	const navigate = useNavigate();

	const localToken = localStorage.getItem('user');
	const [token, setToken] = React.useState<string | null>(localToken && JSON.parse(localToken).token);
	const [username, setUsername] = React.useState<string | null>(localToken && JSON.parse(localToken).username);

	const handleLogin = async (username: string, password: string) => {
		return new Promise((resolve, reject) => {
			AuthService.login(username, password).then(
				(data) => {
					setToken(data.token);
					setUsername(data.username);
					navigate('/Management/Dashboard');
				},
				(error) => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
					console.log(resMessage);
					reject('You have entered an invalid username or password');
				}
			);
		});
	};

	const handleLogout = () => {
		AuthService.logout();
		navigate('/Management/Login');
	};

	const handleForgotPassword = async (username: string): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			AuthService.forgotPassword(username).then(
				(data) => {
					resolve(true);
				},
				(error) => {
					resolve(false);
				}
			);
		});
	};

   const handleResetPassword = async (username: string, password: string, token: string): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			AuthService.resetPassword(username, password, token).then(
				(data) => {
					resolve(true);
				},
				(error) => {
					resolve(false);
				}
			);
		});
	};

	const value: IAuthContext = {
		token,
		username,
		onLogin: handleLogin,
		onLogout: handleLogout,
		onForgotPassword: handleForgotPassword,
		onResetPassword: handleResetPassword,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
