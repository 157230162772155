import { IUser } from "./IUser";

export interface IGame {
    gameId: string,
    name: string
    description: string,
    location: string,
    startDate: Date,
    createDate: Date,
    status: GameStatusEnum,
    publicRegistration: boolean,
    consoleCount: number,
    qualificationRounds: number,
    quarterfinalsEnabled: boolean,
    playerQuarterfinal: number,
    semifinalsEnabled: boolean,
    playerSemifinal: number,
    playerFinal: number,
    playerRound: number,
    user: IUser,
}

export interface ICreateGame {
   name: string;
   description: string;
   location: string;
   startDate: Date;
}

export enum GameStatusEnum {
    Configuration,
    Running,
    Ended
}