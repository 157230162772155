import React from 'react';
import { useSearchParams } from 'react-router-dom';
import NewPasswordComponent from './Components/NewPassword.component';
import ResetPasswordComponent from './Components/ResetPassword.component';

interface IResetPasswordPageProps {}

export default function ResetPasswordPage(props: IResetPasswordPageProps) {
	const [searchParams] = useSearchParams();

	const [resetToken, setResetToken] = React.useState<string>();
	const [resetUsername, setResetUsername] = React.useState<string>();

	if (searchParams.has('token') && !resetToken) {
		console.log('Token found!');
		setResetToken(searchParams.get('token')?.toString().replaceAll(' ', '+'));
		console.log(searchParams.get('token')?.toString());
	}

	if (searchParams.has('username') && !resetUsername) {
		console.log('Username found!');
		setResetUsername(searchParams.get('username')?.toString());
	}

	return (
		<section className='vh-100' style={{ backgroundColor: '#508bfc' }}>
			<div className='container py-5 h-100'>
				<div className='row d-flex justify-content-center align-items-center h-100'>
					<div className='col-12 col-md-8 col-lg-6 col-xl-5'>
						<div className='card shadow-2-strong' style={{ borderRadius: '1rem' }}>
							{resetToken && resetUsername ? <NewPasswordComponent token={resetToken} username={resetUsername} /> : <ResetPasswordComponent />}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
