import axios from 'axios';
import { AppConfiguration } from 'read-appsettings-json';

class AuthService {
	login(username: string, password: string) {
		return axios
			.post(AppConfiguration.Setting().ApiUrl + '/api/Authentication/login', {
				username,
				password,
			})
			.then((response) => {
				if (response.data.token) {
					localStorage.setItem('user', JSON.stringify(response.data));
				}
				return response.data;
			});
	}

	logout() {
		localStorage.removeItem('user');
	}

	register(username: string, email: string, password: string) {
		return axios.post(AppConfiguration.Setting().ApiUrl + '/api/Authentication/register', {
			username,
			email,
			password,
		});
	}

   forgotPassword(username: string) {
		return axios.post(AppConfiguration.Setting().ApiUrl + '/api/Authentication/forgotPassword', {
			username
		});
	}

   resetPassword(username: string, password: string, token: string) {
		return axios.post(AppConfiguration.Setting().ApiUrl + '/api/Authentication/resetPassword', {
			username,
         password,
         token
		});
	}

	getCurrentUser() {
		if (localStorage.getItem('user') != null) return JSON.parse(localStorage.getItem('user')!);
	}
}
const authService = new AuthService()
export default authService;
