import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { IPlayer } from '../../../../../../DataProvider/Models/IPlayer';
import { IPlayerDataProvider } from '../../../../../../DataProvider/PlayerDataProvider/IPlayerDataProvider';
import { PlayerApiDataProvider } from '../../../../../../DataProvider/PlayerDataProvider/PlayerApiDataProvider';

interface IAddPlayerDialog {
	gameId: string;
	onClose(): void;
	onRefresh(): void;
}

export default function AddPlayerDialog(props: IAddPlayerDialog) {
	const playerDataProvider: IPlayerDataProvider = new PlayerApiDataProvider();

	const newPlayer: IPlayer = {
		username: '',
		mail: '',
		confirmed: true,
		registerDate: new Date(),
		playerId: '',
	};
	const [errorMsg, setErrorMsg] = React.useState<string>('');
	const [player, setPlayer] = React.useState<IPlayer>(newPlayer);

	function onClose() {
		props.onClose();
	}

	function handelUsernameChange(event: any) {
		setPlayer((prevState) => ({
			...prevState,
			username: event.target.value,
		}));
	}

	function handelMailChange(event: any) {
		setPlayer((prevState) => ({
			...prevState,
			mail: event.target.value,
		}));
	}

	function handelConfirmChange(event: any) {
		setPlayer((prevState) => ({
			...prevState,
			confirmed: event.target.checked,
		}));
	}

	function onCreate() {
		playerDataProvider
			.createPlayer(props.gameId, player)
			.then((values) => {
				props.onRefresh();
				onClose();
			})
			.catch((error) => setErrorMsg(error));
	}

	return (
		<Modal show={true} onHide={() => {}} centered>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						Add Player
					</h5>
					<button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={onClose}></button>
				</div>
				<div className='modal-body'>
					<form>
						<div className='mb-3'>
							<label htmlFor='playerUsernameInput' className='form-label'>
								Username*
							</label>
							<input type='text' className='form-control' id='playerUsernameInput' autoFocus value={player.username} onChange={handelUsernameChange} />
						</div>
						<div className='mb-3'>
							<label htmlFor='playerMailInput' className='form-label'>
								Email address
							</label>
							<input type='email' className='form-control' id='playerMailInput' value={player.mail} onChange={handelMailChange} />
						</div>
						<div className='mb-3 form-check'>
							<input type='checkbox' className='form-check-input' id='playerConfirmedCheckbox' checked={player.confirmed} onChange={handelConfirmChange} />
							<label className='form-check-label' htmlFor='playerConfirmedCheckbox'>
								Confirmed
							</label>
						</div>

						{errorMsg && <p className='lead text-danger'>{errorMsg}</p>}
					</form>
				</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={onClose}>
						Close
					</button>
					<button type='button' className='btn btn-primary' onClick={onCreate}>
						Create
					</button>
				</div>
			</div>
		</Modal>
	);
}
