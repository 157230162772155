import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IPlayer } from '../../../../../DataProvider/Models/IPlayer';
import { IPlayerDataProvider } from '../../../../../DataProvider/PlayerDataProvider/IPlayerDataProvider';
import { PlayerApiDataProvider } from '../../../../../DataProvider/PlayerDataProvider/PlayerApiDataProvider';
import '../../GamePage.scss';
import AddPlayerDialog from './Dialogs/AddPlayerDialog';

interface IPlayerBoxProps {
	player: IPlayer[];
	gameId: string;
	onRefresh(): void;
	hideConfirm?: boolean;
	hideDelete?: boolean;
	hideAdd?: boolean;
}

export default function PlayerBox(props: IPlayerBoxProps) {
	const playerDataProvider: IPlayerDataProvider = new PlayerApiDataProvider();

	const [showAddPlayer, setShowAddPlayer] = React.useState<boolean>(false);

	function handleConfirmPlayer(playerId: string) {
		const playerUpdate = props.player.find((x) => x.playerId === playerId);
		playerUpdate!.confirmed = true;
		playerDataProvider.updatePlayer(playerUpdate!).then((values) => {
			props.onRefresh();
		});
	}

	function handleDeletePlayer(playerId: string) {
		playerDataProvider.deletePlayer(playerId!).then((values) => {
			props.onRefresh();
		});
	}

	const tablePlayer = props.player
		.sort((a, b) => b.score! - a.score!)
		.map((player, i) => {
			const className = player.confirmed ? 'playerConfirmed' : 'playerNotConfirmed';
			return (
				<tr className={className}>
					<td>{i + 1}</td>
					<td>{player.username}</td>
					<td>{player.score}</td>

					{(!props.hideConfirm || !props.hideDelete) && (
						<td className='text-end'>
							{!props.hideConfirm && (
								<button className='btn border-0' disabled={player.confirmed} onClick={() => handleConfirmPlayer(player.playerId)}>
									<FontAwesomeIcon icon={faCheck} />
								</button>
							)}
							{!props.hideDelete && (
								<button className='btn btnDelete' onClick={() => handleDeletePlayer(player.playerId)}>
									<FontAwesomeIcon icon={faTrash} />
								</button>
							)}
						</td>
					)}
				</tr>
			);
		});

	return (
		<>
			<div className='row'>
				<div className='col-md-4'>
					<h3>Player ({props.player.length})</h3>
				</div>
				<div className='col-md-8'>
					{!props.hideAdd && (
						<button className='float-end btn btn-primary' onClick={() => setShowAddPlayer(true)}>
							<FontAwesomeIcon icon={faPlus} /> Add Player
						</button>
					)}
				</div>
			</div>
			<hr />
			<table className='table table-striped table-hover'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Username</th>
						<th scope='col'>Score</th>
						{(!props.hideConfirm || !props.hideDelete) && (
							<th scope='col' className='text-end'>
								Action
							</th>
						)}
					</tr>
				</thead>
				<tbody>{tablePlayer}</tbody>
			</table>
			{showAddPlayer && <AddPlayerDialog gameId={props.gameId} onClose={() => setShowAddPlayer(false)} onRefresh={props.onRefresh} />}
		</>
	);
}
