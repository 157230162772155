import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../Auth/useAuthHook';

interface ILoginComponentProps {}

export default function LoginComponent(props: ILoginComponentProps) {
	const { onLogin } = useAuth();
	const navigate = useNavigate();

	const [username, setUsername] = React.useState<string>();
	const [password, setPassword] = React.useState<string>();
	const [loading, setLoading] = React.useState<boolean>();
	const [message, setMessage] = React.useState<string>();

	function onChangeUsername(e: any) {
		setUsername(e.target.value);
	}
	function onChangePassword(e: any) {
		setPassword(e.target.value);
	}
	function handleLogin(e: any) {
		e.preventDefault();

		if (!username || !password) return;

		setLoading(true);
		setMessage('');

		onLogin(username, password)
			.then((x: any) => {})
			.catch((error: any) => {
				setMessage(error);
				setLoading(false);
			});
	}

	function handleForgotPassword(e: any) {
		navigate('/Management/ResetPassword');
	}

	return (
		<div className='card-body p-5 text-center'>
			<h3 className='mb-5'>Sign in</h3>
			<form>
				<div className='form-outline mb-4'>
					<input type='text' className='form-control form-control-lg' placeholder='Username' value={username} onChange={onChangeUsername} />
				</div>

				<div className='form-outline mb-4'>
					<input type='password' className='form-control form-control-lg' placeholder='Password' value={password} onChange={onChangePassword} />
				</div>

				{message && <p className='lead text-danger'>{message}</p>}

				<button className='btn btn-primary btn-lg btn-block' disabled={loading} onClick={handleLogin}>
					{loading && <span className='spinner-border spinner-border-sm'></span>}
					<span> Login</span>
				</button>

				<br />
				<br />

				<button type='button' className='btn text-grey' onClick={handleForgotPassword}>
					Forgot password?
				</button>
			</form>
		</div>
	);
}
