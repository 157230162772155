import axios from 'axios';
import { AppConfiguration } from 'read-appsettings-json';
import authHeader from '../../Auth/auth-header';
import { ICreateGame, IGame } from '../Models/IGame';
import { IPlayer } from '../Models/IPlayer';
import { IGameDataProvider } from './IGameDataProvider';

export class GameApiDataProvider implements IGameDataProvider {
	getGame(id: string): Promise<IGame> {
		return new Promise<IGame>((resolve, reject) => {
			axios.get(AppConfiguration.Setting().ApiUrl + '/api/Game/' + id).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}

   createGame(game: ICreateGame): Promise<IGame> {
		return new Promise<IGame>((resolve, reject) => {
			axios.post(AppConfiguration.Setting().ApiUrl + '/api/Game/', game, { headers: authHeader() }).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}

	updateGame(id: string, game: IGame): Promise<IGame> {
		const ConsoleCount = game.consoleCount;
		const SemifinalsEnabled = game.semifinalsEnabled;
		const QuarterfinalsEnabled = game.quarterfinalsEnabled;
		const PublicRegistration = game.publicRegistration;
		const PlayerRound = game.playerRound;
		const QualificationRounds = game.qualificationRounds;
		return new Promise<IGame>((resolve, reject) => {
			axios
				.post(
					AppConfiguration.Setting().ApiUrl + '/api/Game/' + id,
					{
						ConsoleCount,
						SemifinalsEnabled,
						PlayerSemifinal: game.playerSemifinal,
						QuarterfinalsEnabled,
						PlayerQuarterfinal: game.playerQuarterfinal,
						PlayerFinal: game.playerFinal,
						PublicRegistration,
						PlayerRound,
						QualificationRounds,
					},
					{ headers: authHeader() }
				)
				.then((res) => {
					if (res.status >= 200 && res.status < 400) {
						resolve(res.data);
					}
				});
		});
	}

   deleteGame(gameId: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			axios.delete(AppConfiguration.Setting().ApiUrl + '/api/Game/' + gameId, { headers: authHeader() }).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}

   startGame(id: string): Promise<IGame> {
		return new Promise<IGame>((resolve, reject) => {
			axios.get(AppConfiguration.Setting().ApiUrl + '/api/Game/' + id + '/start', { headers: authHeader() }).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}

	getPlayer(id: string): Promise<IPlayer[]> {
		return new Promise<IPlayer[]>((resolve, reject) => {
			axios.get(AppConfiguration.Setting().ApiUrl + '/api/Game/' + id + '/Player', { headers: authHeader() }).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}
}
