import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameApiDataProvider } from '../../../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../../../DataProvider/GameDataProvider/IGameDataProvider';
import { IGame } from '../../../DataProvider/Models/IGame';
import { UserApiDataProvider } from '../../../DataProvider/UserDataProvider/UserApiDataProvider';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import CreateGameDialog from './Components/Dialogs/CreateGameDialog';
import GameCard from './Components/GameCard';

export default function DashboardPage() {
	const userDataProvider: UserApiDataProvider = new UserApiDataProvider();
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();
	const navigate = useNavigate();

	const [games, setGames] = React.useState<IGame[]>();
	const [showCreateGame, setShowCreateGame] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState({
		loadGames: true,
	});

	useEffect(() => {
		loadGames();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGames(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadGames: true,
			}));
		userDataProvider.getGames().then((value) => {
			setGames(value);
			setLoading((prevState) => ({
				...prevState,
				loadGames: false,
			}));
		});
	}

	function onOpen(id: string) {
		navigate('/Management/Game/' + id);
	}

	function onDelete(id: string) {
		gameDataProvider.deleteGame(id).then((value) => {
			onRefresh(true);
		});
	}

	function onRefresh(silent: boolean = true) {
		loadGames(true);
	}

	function onOpenDialog() {
		setShowCreateGame(true);
	}

	function onCloseDialog() {
		setShowCreateGame(false);
	}

	return (
		<>
			<HeaderPublic />
			<div className='container'>
				<h1 className='display-1'>Dashboard</h1>
				<button className='btn btn-primary' onClick={onOpenDialog}>
					Create
				</button>
				<br /> <br />
				<div className='row'>
					{!loading.loadGames ? (
						games
							?.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())
							.map((game) => {
								return <GameCard game={game} onOpen={onOpen} onDelete={onDelete} />;
							})
					) : (
						<div className='spinner-border' role='status'></div>
					)}
				</div>
				{showCreateGame && <CreateGameDialog onClose={onCloseDialog} onRefresh={onRefresh} />}
			</div>
		</>
	);
}
