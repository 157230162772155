import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IMatchDataProvider } from '../../../../../DataProvider/MatchDataProvider/IMatchDataProvider';
import { MatchApiDataProvider } from '../../../../../DataProvider/MatchDataProvider/MatchApiDataProvider';
import { IMatch, IMatchPlayer, MapIcons, Maps, MatchPhases, MatchStatusEnum } from '../../../../../DataProvider/Models/IMatch';
import { IPlayer } from '../../../../../DataProvider/Models/IPlayer';
import '../../GamePage.scss';

interface IMatchBoxProps {
	match: IMatch;
	playerRound: number;
	player: IPlayer[];
	class: string;
	index: number;
	onRefresh(): void;
	canPrepare: boolean;
}

export default function MatchBox(props: IMatchBoxProps) {
	const matchDataProvider: IMatchDataProvider = new MatchApiDataProvider();

	const [playerCount, setPlayerCount] = React.useState<{ [x: string]: number | string }>();
	const [locked, setLocked] = React.useState<boolean>(true);

	React.useEffect(() => {
		const player: { [x: string]: number | string } = {};
		props.match.player.forEach((p) => {
			player[p.playerId] = p.points;
		});
		setPlayerCount(player);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handlePointsChange(event: any, player: IMatchPlayer) {
		setPlayerCount((prevState) => ({
			...prevState,
			[player.playerId]: event.target.value,
		}));
	}

	function onSave() {
		const matchPlayer: IMatchPlayer[] = props.match.player.map((p) => ({
			...p,
			points: (playerCount && playerCount[p.playerId].toString()) || '0',
		}));
		setLocked(true);
		matchDataProvider.setPoints(matchPlayer, props.match.matchId).then((values) => {
			matchDataProvider.setStatus({ ...props.match, status: MatchStatusEnum.Ended }).then((values) => {
				props.onRefresh();
			});
		});
	}

	function onStart() {
		matchDataProvider.setStatus({ ...props.match, status: MatchStatusEnum.Running }).then((values) => {
			props.onRefresh();
		});
	}

	function onPrepare() {
		matchDataProvider.setStatus({ ...props.match, status: MatchStatusEnum.Preparing }).then((values) => {
			props.onRefresh();
		});
	}

	function placeholder() {
		const count = props.playerRound - props.match.player.length;
		const items = [];

		for (let index = 0; index < count; index++) {
			items.push(
				<li className='list-group-item'>
					<div className='row'>
						<div className='col-md-10'>-</div>
					</div>
				</li>
			);
		}

		return items;
	}
	const editable = props.match.status === MatchStatusEnum.Running || (props.match.status === MatchStatusEnum.Ended && !locked);

	return (
		<div className={props.class + ' mb-5'}>
			<div className={props.match.status === MatchStatusEnum.Running ? 'card card-active' : props.match.status === MatchStatusEnum.Preparing ? 'card card-prepare' : 'card'}>
				<div className='card-body'>
					<div className='row'>
						<div className='col-md-9'>
							<h5 className='card-title'>Match #{props.index + 1}</h5>
							{props.match.console > 0 && <h6 className='card-title text-primary'>Konsole: {props.match.console}</h6>}
							<h6 className='card-subtitle mb-2 text-muted'>{MatchPhases[props.match.phase]}</h6>
							<div className='row align-items-center'>
								<div className='col-md-2 image-container'>
									<img src={MapIcons[props.match.map]} alt={Maps[props.match.map] + '-Icon'} width={'50px'} />
								</div>
								<div className='col-md-10'>
									<h6 className='card-subtitle mb-0 text-muted'>{Maps[props.match.map]}</h6>
								</div>
							</div>
						</div>
						<div className='col-md-3 text-end'>
							{props.match.status === MatchStatusEnum.Preparing && (
								<h5>
									<span className='badge rounded-pill bg-warning text-dark'>Vorbereitung</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.Running && (
								<h5>
									<span className='badge rounded-pill bg-success'>Läuft</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.Ended && (
								<h5>
									<span className='badge rounded-pill bg-dark'>Beendet</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.NotStarted && (
								<h5>
									<span className='badge rounded-pill bg-secondary'>Nicht gestartet</span>
								</h5>
							)}
						</div>
					</div>
				</div>
				<ul className='list-group list-group-flush'>
					{props.match.player.map((x, i) => {
						return (
							<li className='list-group-item'>
								<div className='row'>
									<div className='col-md-10'>{props.player.find((y) => y.playerId === x.playerId)?.username}</div>
									<div className='col-md-2'>
										<input
											type='number'
											disabled={!editable}
											className={'form-control score-box-' + i.toString()}
											value={playerCount && playerCount[x.playerId] && playerCount[x.playerId]}
											onChange={(e) => handlePointsChange(e, x)}
										/>
									</div>
								</div>
							</li>
						);
					})}
					{placeholder()}
				</ul>
				<div className='card-body btn-block btn-group'>
					{props.match.status === MatchStatusEnum.Preparing && (
						<button className='btn btn-primary' onClick={onStart}>
							Start
						</button>
					)}
					{props.match.status === MatchStatusEnum.Ended && locked && (
						<button className='btn' onClick={() => setLocked(false)}>
							<FontAwesomeIcon icon={faLock} />
						</button>
					)}
					{editable && (
						<button className='btn btn-primary' onClick={onSave}>
							Save
						</button>
					)}

					{props.match.status === MatchStatusEnum.Ended && !locked && (
						<button className='btn' onClick={() => setLocked(true)}>
							<FontAwesomeIcon icon={faLockOpen} />
						</button>
					)}
					{props.match.status === MatchStatusEnum.NotStarted && props.canPrepare && (
						<button className='btn btn-primary' onClick={onPrepare}>
							Prepare
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
