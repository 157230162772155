import React from "react";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/esm/Modal";
import { GameApiDataProvider } from "../../../../../DataProvider/GameDataProvider/GameApiDataProvider";
import { IGameDataProvider } from "../../../../../DataProvider/GameDataProvider/IGameDataProvider";
import { ICreateGame } from "../../../../../DataProvider/Models/IGame";
import "react-datepicker/dist/react-datepicker.css";

interface IAddPlayerDialog {
	onClose(): void;
	onRefresh(): void;
}

export default function CreateGameDialog(props: IAddPlayerDialog) {
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();

	const newGame: ICreateGame = {
		name: "",
		description: "",
		location: "",
		startDate: new Date(),
	}
	const [game, setGame] = React.useState<ICreateGame>(newGame);

	function onClose() {
		props.onClose();
	}

	function handleNameChange(event: any) {
		setGame((prevState) => ({
			...prevState,
			name: event.target.value
		}));
	}
	
	function handelDescriptionChange(event: any) {
		setGame((prevState) => ({
			...prevState,
			description: event.target.value
		}));
	}

	function handelLocationChange(event: any) {
		setGame((prevState) => ({
			...prevState,
			location: event.target.value
		}));
	}

   function handelStartDateChange(date: Date) {
		setGame((prevState) => ({
			...prevState,
			startDate: date
		}));
	}

	function onCreate() {
		gameDataProvider.createGame(game).then((values) => {
			props.onRefresh()
			onClose()
		})
	}

	return (
		<Modal show={true} onHide={() => {}} centered>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						Create Game
					</h5>
					<button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={onClose}></button>
				</div>
				<div className='modal-body'>
					<form>
						<div className='mb-3'>
							<label htmlFor='gameNameInput' className='form-label'>
								Name
							</label>
							<input type='text' className='form-control' id='gameNameInput' value={game.name} onChange={handleNameChange} />
						</div>
						<div className='mb-3'>
							<label htmlFor='gameDescriptionInput' className='form-label'>
								Description
							</label>
							<input type='email' className='form-control' id='gameDescriptionInput' value={game.description} onChange={handelDescriptionChange} />
						</div>
                  <div className='mb-3'>
							<label htmlFor='gameLocationInput' className='form-label'>
								Location
							</label>
							<input type='email' className='form-control' id='gameLocationInput' value={game.location} onChange={handelLocationChange} />
						</div>
                  <div className='mb-3'>
							<label htmlFor='gameLocationInput' className='form-label'>
								Date
							</label>
							<DatePicker selected={game.startDate} onChange={(date:Date) => handelStartDateChange(date)} />
						</div>
					</form>
				</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={onClose}>
						Close
					</button>
					<button type='button' className='btn btn-primary' onClick={onCreate}>
						Create
					</button>
				</div>
			</div>
		</Modal>
	);
}
