import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import AuthProvider from './Auth/AuthProvider';
import AuthRoute from './Auth/AuthRoute';
import DashboardPage from './Management/Pages/DashboardPage/DashboardPage';
import GamePage from './Management/Pages/GamePage/GamePage';
import PublicGamePage from './Public/Pages/GamePage/GamePage';
import LoginPage from './Management/Pages/LoginPage/LoginPage';
import RegisterPage from './Management/Pages/RegisterPage/RegisterPage';
import HomePage from './Public/Pages/Home/Home';
import RegistrationPage from './Public/Pages/RegistrationPage/RegistrationPage';
import DisplayPage from './Displays/DisplayPage';
import ResetPasswordPage from './Management/Pages/ResetPasswordPage/ResetPasswordPage';

function App() {
	return (
		<AuthProvider>
			<div className='App'>
				<Routes>
					<Route index element={<HomePage />} />
					<Route path='Game/:id' element={<PublicGamePage />} />
					<Route path='Game/:id/Register' element={<RegistrationPage />} />
					<Route path='Game/:id/Display/:did' element={<DisplayPage />} />
					<Route path='Management/Login' element={<LoginPage />} />
					<Route path='Management/Register' element={<RegisterPage />} />
					<Route path='Management/ResetPassword' element={<ResetPasswordPage />} />
					<Route path='Management/ResetPassword/:token' element={<ResetPasswordPage />} />
					<Route
						path='Management'
						element={
							<>
								<Navigate replace={true} to='/Management/Dashboard' />
							</>
						}
					/>
					<Route
						path='Management/Dashboard'
						element={
							<AuthRoute>
								<DashboardPage />
							</AuthRoute>
						}
					/>
					<Route
						path='Management/Game/:id'
						element={
							<AuthRoute>
								<GamePage />
							</AuthRoute>
						}
					/>
					<Route path='Home' element={<HomePage />} />
					<Route path='*' element={<HomePage />} />
				</Routes>
			</div>
		</AuthProvider>
	);
}

export default App;
