import { Link } from 'react-router-dom';
import Logo from '../../../Assets/logo';
import { useAuth } from '../../../Auth/useAuthHook';
import './Header.scss';

export default function HeaderPublic() {
	const { onLogout, username } = useAuth();

	return (
		<nav className='navbar navbar-expand-lg navbar-light bg-light'>
			<div className='container'>
				<Logo className='logo-header' />
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
						<li className='nav-item'>
							<Link className='nav-link active' to='/Management/Dashboard'>
								Games
							</Link>
						</li>
						{/* <li className='nav-item'>
							<a className='nav-link' >
								Link
							</a>
						</li>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								Dropdown
							</a>
							<ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' >
										Action
									</a>
								</li>
								<li>
									<a className='dropdown-item' >
										Another action
									</a>
								</li>
								<li>
									<hr className='dropdown-divider' />
								</li>
								<li>
									<a className='dropdown-item' >
										Something else here
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item'>
							<a className='nav-link disabled'  aria-disabled='true'>
								Disabled
							</a>
						</li> */}
					</ul>
					<div className='d-flex'>
						<span className='navbar-text' style={{ marginRight: '10px' }}>
							{username}
						</span>
						<button className='btn btn-primary' onClick={onLogout}>
							Logout
						</button>
					</div>
				</div>
			</div>
		</nav>
	);
}
