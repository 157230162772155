import axios from 'axios';
import { AppConfiguration } from 'read-appsettings-json';
import authHeader from '../../Auth/auth-header';
import { IPlayer } from '../Models/IPlayer';
import { IPlayerDataProvider } from './IPlayerDataProvider';

export class PlayerApiDataProvider implements IPlayerDataProvider {
	createPlayer(gameId: string, player: IPlayer): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			axios
				.post(
					AppConfiguration.Setting().ApiUrl + '/api/Player/',
					{
						GameId: gameId,
						Username: player.username,
						Mail: player.mail,
						Confirmed: player.confirmed,
					},
					{ headers: authHeader() }
				)
				.then((res) => {
					if (res.status >= 200 && res.status < 400) {
						resolve(res.data);
					}
				})
				.catch((error) => {
               console.log(error.response)
					reject(error.response.data);
				});
		});
	}

	createPlayerPublic(gameId: string, username: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			axios
				.post(AppConfiguration.Setting().ApiUrl + '/api/Player/Public', {
					GameId: gameId,
					Username: username,
					Mail: '',
					Confirmed: true,
				})
				.then((res) => {
					if (res.status >= 200 && res.status < 400) {
						resolve(res.data);
					} else {
						console.log(res);
						reject(res.data);
					}
				})
				.catch((error) => {
					console.log(error.response);
					reject(error.response.data);
				});
		});
	}

	updatePlayer(player: IPlayer): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			axios
				.post(
					AppConfiguration.Setting().ApiUrl + '/api/Player/' + player.playerId,
					{
						Username: player.username,
						Mail: player.mail,
						Confirmed: player.confirmed,
					},
					{ headers: authHeader() }
				)
				.then((res) => {
					if (res.status >= 200 && res.status < 400) {
						resolve(res.data);
					}
				});
		});
	}

	deletePlayer(playerId: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			axios.delete(AppConfiguration.Setting().ApiUrl + '/api/Player/' + playerId, { headers: authHeader() }).then((res) => {
				if (res.status >= 200 && res.status < 400) {
					resolve(res.data);
				}
			});
		});
	}
}
