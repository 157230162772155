import { Navigate } from "react-router-dom";
import { useAuth } from "./useAuthHook";

export default function AuthRoute(props: any) {
	const { token } = useAuth();

	if (!token) {
		return <Navigate to='/Management/Login' replace />;
	}

	return props.children;
};
