import React from 'react';
import AuthService from '../../../../Auth/AuthService';
import { useNavigate } from 'react-router-dom';

interface IRegisterComponentProps {}

export default function LoginComponent(props: IRegisterComponentProps) {
	const navigate = useNavigate();

	const [username, setUsername] = React.useState<string>();
	const [password, setPassword] = React.useState<string>();
	const [mail, setMail] = React.useState<string>();
	const [loading, setLoading] = React.useState<boolean>();
	const [successful, setSuccessful] = React.useState<boolean>();
	const [message, setMessage] = React.useState<string>();

	function onChangeUsername(e: any) {
		setUsername(e.target.value);
	}

	function onChangeEmail(e: any) {
		setMail(e.target.value);
	}

	function onChangePassword(e: any) {
		setPassword(e.target.value);
	}

	function handleRegister(e: any) {
		e.preventDefault();

		setMessage('');
		setSuccessful(false);

		if (!username || !mail || !password) return;

		setLoading(true);

		AuthService.register(username, mail, password).then(
			(response) => {
				console.log('Register DONE!');
				setMessage(response.data.message);
				setSuccessful(true);
				handleAfterRegister();
			},
			(error) => {
				console.log('Register ERROR!');
				const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setMessage(resMessage);
				setSuccessful(false);
				setLoading(false);
			}
		);
	}

	function handleAfterRegister() {
		navigate('/Management/Login');
	}

	return (
		<div className='card-body p-5 text-center'>
			<h3 className='mb-5'>Sign Up</h3>
			<form>
				<div className='form-outline mb-4'>
					<input type='text' className='form-control form-control-lg' placeholder='Username' value={username} onChange={onChangeUsername} />
				</div>

				<div className='form-outline mb-4'>
					<input type='text' className='form-control form-control-lg' placeholder='Mail' value={mail} onChange={onChangeEmail} />
				</div>

				<div className='form-outline mb-4'>
					<input type='password' className='form-control form-control-lg' placeholder='Password' value={password} onChange={onChangePassword} />
				</div>

				{message && (
					<div className='form-group'>
						<div className={successful ? 'alert alert-success' : 'alert alert-danger'} role='alert'>
							{message}
						</div>
					</div>
				)}

				<button className='btn btn-primary btn-lg btn-block' disabled={loading} onClick={handleRegister}>
					{loading && <span className='spinner-border spinner-border-sm'></span>}
					<span>Sign Up</span>
				</button>
			</form>
		</div>
	);

	// return (
	// 	<div className='card-body p-5 text-center'>
	// 		<h3 className='mb-5'>Register</h3>
	// 		<form onSubmit={handleRegister}>
	// 			{!successful && (
	// 				<div>
	// 					<div className='form-outline mb-4'>
	// 						<label htmlFor='username'>Username</label>
	// 						<input type='text' className='form-control' name='username' value={username} onChange={onChangeUsername} />
	// 					</div>
	// 					<div className='form-group'>
	// 						<label htmlFor='email'>Email</label>
	// 						<input type='text' className='form-control' name='email' value={mail} onChange={onChangeEmail} />
	// 					</div>
	// 					<div className='form-group'>
	// 						<label htmlFor='password'>Password</label>
	// 						<input type='password' className='form-control' name='password' value={password} onChange={onChangePassword} />
	// 					</div>
	// 					<div className='form-group'>
	// 						<button className='btn btn-primary btn-block'>Sign Up</button>
	// 					</div>
	// 				</div>
	// 			)}
	// 			{message && (
	// 				<div className='form-group'>
	// 					<div className={successful ? 'alert alert-success' : 'alert alert-danger'} role='alert'>
	// 						{message}
	// 					</div>
	// 				</div>
	// 			)}
	// 		</form>
	// 	</div>
	// );
}
