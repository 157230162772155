import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import 'react-datepicker/dist/react-datepicker.css';

interface IDisplayDialog {
	onClose(): void;
	gameId: string;
	consoleCount: number;
}

export default function DisplayDialog(props: IDisplayDialog) {
	function onClose() {
		props.onClose();
	}

	const displayLinks = () => {
		let links = [];
		for (let index = 0; index < props.consoleCount; index++) {
			const link = window.location.protocol + '//' + window.location.host + '/Game/' + props.gameId + '/Display/' + (index + 1);
			links.push(
				<>
					<div>
						<div className=' mb-3'>
							<label className='form-label'>Display for console #{index + 1}</label>
							<div className='input-group'>
								<input type='text' className='form-control' value={link} />
								<button
									className='btn btn-outline-secondary'
									type='button'
									onClick={() => {
										navigator.clipboard.writeText(link);
									}}
								>
									<FontAwesomeIcon icon={faClipboard} />
								</button>
							</div>
							{index + 1 < props.consoleCount && <br />}
						</div>
					</div>
				</>
			);
		}

		return links;
	};

	return (
		<Modal show={true} onHide={() => {}} centered size='lg'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						Displaylinks
					</h5>
					<button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={onClose}></button>
				</div>
				<div className='modal-body'>{displayLinks()}</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={onClose}>
						Close
					</button>
				</div>
			</div>
		</Modal>
	);
}
