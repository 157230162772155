import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../Auth/useAuthHook';

interface INewPasswordComponentProps {
	username: string;
	token: string;
}

export default function NewPasswordComponent(props: INewPasswordComponentProps) {
	const { onResetPassword } = useAuth();
	const navigate = useNavigate();

	const [password, setPassword] = React.useState<string>();
	const [loading, setLoading] = React.useState<boolean>();
	const [message, setMessage] = React.useState<string>();
	const [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);

	function onChangePassword(e: any) {
		setPassword(e.target.value);
	}

	function handleResetPassword(e: any) {
		e.preventDefault();

		if (!password) return;

		setLoading(true);
		setMessage('');

		onResetPassword(props.username, password, props.token)
			.then((x: any) => {
				console.log(x);
				setLoading(false);
				setPasswordChanged(true);
			})
			.catch((error: any) => {
				setMessage(error);
				setLoading(false);
			});
	}

	function handleLogin(e: any) {
		navigate('/Management/Login');
	}

	return (
		<div className='card-body p-5 text-center'>
			<h3 className='mb-5'>Enter new Password</h3>
			{!passwordChanged ? (
				<>
					<form>
						<div className='form-outline mb-4'>
							<input type='password' className='form-control form-control-lg' placeholder='Password' value={password} onChange={onChangePassword} />
						</div>

						{message && <p className='lead text-danger'>{message}</p>}

						<button className='btn btn-primary btn-lg btn-block' disabled={loading} onClick={handleResetPassword}>
							{loading && <span className='spinner-border spinner-border-sm'></span>}
							<span> Set Password </span>
						</button>
					</form>
				</>
			) : (
				<>
					<p className='lead text-success'>Your password was successfully resetted!</p>
					<button className='btn btn-primary btn-lg btn-block' disabled={loading} onClick={handleLogin}>
						{loading && <span className='spinner-border spinner-border-sm'></span>}
						<span> Go to Login </span>
					</button>
				</>
			)}
		</div>
	);
}
