import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import 'react-datepicker/dist/react-datepicker.css';
import { IGame } from '../../../../../DataProvider/Models/IGame';

interface IDeleteGameDialog {
	onClose(): void;
	onDelete(id: string): void;
	game: IGame;
}

export default function DeleteGameDialog(props: IDeleteGameDialog) {
	function onClose() {
		props.onClose();
	}

   function onDelete(id: string) {
		props.onDelete(id);
	}

	return (
		<Modal show={true} onHide={() => {}} centered size='lg'>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						Delete
					</h5>
					<button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={onClose}></button>
				</div>
				<div className='modal-body'>
					<p className='lead'>Are you sure that you want to delete the Game <strong>{props.game.name}</strong>?</p>
				</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={onClose}>
						Close
					</button>
               <button type='button' className='btn btn-danger' data-bs-dismiss='modal' onClick={() => onDelete(props.game.gameId)}>
						Delete
					</button>
				</div>
			</div>
		</Modal>
	);
}
