import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IMatch, MatchPhases, MatchStatusEnum } from '../../../../DataProvider/Models/IMatch';
import { IPlayer } from '../../../../DataProvider/Models/IPlayer';
import '../GamePage.scss';

interface IMatchBoxProps {
	match: IMatch;
	playerRound: number;
	player: IPlayer[];
	class: string;
	index: number;
}

export default function MatchBoxPublic(props: IMatchBoxProps) {
	const [playerCount, setPlayerCount] = React.useState<{ [x: string]: number | string }>();

	React.useEffect(() => {
		const player: { [x: string]: number | string } = {};
		props.match.player.forEach((p) => {
			player[p.playerId] = p.points;
		});
		setPlayerCount(player);
	}, [props.match]);

	function placeholder() {
		const count = props.playerRound - props.match.player.length;
		const items = [];

		for (let index = 0; index < count; index++) {
			items.push(
				<li className='list-group-item'>
					<div className='row'>
						<div className='col-md-10'>-</div>
					</div>
				</li>
			);
		}

		return items;
	}

	return (
		<div className={props.class + ' mb-5'}>
			<div className='card card-trans'>
				<div className='card-body'>
					<div className='row'>
						<div className='col'>
							<h5 className='card-title text-white'>Match #{props.index + 1}</h5>
							{props.match.console > 0 && (props.match.status === MatchStatusEnum.Running || props.match.status === MatchStatusEnum.Preparing) && (
								<h6 className='card-title text-primary'>Konsole: {props.match.console}</h6>
							)}
							<h6 className='card-subtitle mb-2 text-white'>{MatchPhases[props.match.phase]}</h6>
						</div>
						<div className='col text-end'>
							{props.match.status === MatchStatusEnum.Preparing && (
								<h5>
									<span className='badge rounded-pill bg-warning'>Vorbereitung</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.Running && (
								<h5>
									<span className='badge rounded-pill bg-success'>Läuft</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.Ended && (
								<h5>
									<span className='badge rounded-pill bg-dark'>Beendet</span>
								</h5>
							)}

							{props.match.status === MatchStatusEnum.NotStarted && (
								<h5>
									<span className='badge rounded-pill bg-secondary'>Nicht gestartet</span>
								</h5>
							)}
						</div>
					</div>
					{/* <p className='card-text'>{props.game.description}</p> */}
				</div>
				<ul className='list-group list-group-flush card-trans2 '>
					{props.match.player.map((x, i) => {
						return (
							<li className='list-group-item'>
								<div className='row'>
									<div className='col-md-5 text-white'>{props.player.find((y) => y.playerId === x.playerId)?.username}</div>

									<div className='col-md-5 text-white'>
										{(props.match.status === MatchStatusEnum.Running || props.match.status === MatchStatusEnum.Preparing) && (
											<>
												<FontAwesomeIcon icon={faGamepad} /> {i + 1}
											</>
										)}
									</div>

									<div className='col-md-2 text-end text-white'>{playerCount && playerCount[x.playerId] ? playerCount[x.playerId] : 0} ({props.player.find(y => y.playerId === x.playerId)?.score})</div>
								</div>
							</li>
						);
					})}
					{placeholder()}
				</ul>
			</div>
		</div>
	);
}
