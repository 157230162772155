import { IPlayer } from '../../../../../DataProvider/Models/IPlayer';
import '../../GamePage.scss';
import './Scoreboard.scss';

interface IPlayerBoxProps {
	player: IPlayer[];
   inverted?: boolean;
}

export default function Scoreboard(props: IPlayerBoxProps) {

	const tablePlayer = props.player
		.sort((a, b) => b.score! - a.score!)
		.map((player, i) => {
			return (
				<tr>
					<td>{i + 1}</td>
					<td>{player.username}</td>
					<td className="text-end">{player.score}</td>
				</tr>
			);
		});

	return (
		<>
			<table className={props.inverted ? 'table table-striped table-hover customTable tableInverted' : 'table table-striped table-hover customTable'} >
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>Username</th>
						<th scope='col' className="text-end">Score</th>
					</tr>
				</thead>
				<tbody>{tablePlayer}</tbody>
			</table>
		</>
	);
}
