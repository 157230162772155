import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'react-qr-code';
import React from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../Assets/logo';
import { GameApiDataProvider } from '../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../DataProvider/GameDataProvider/IGameDataProvider';
import { IMatchDataProvider } from '../DataProvider/MatchDataProvider/IMatchDataProvider';
import { MatchApiDataProvider } from '../DataProvider/MatchDataProvider/MatchApiDataProvider';
import { GameStatusEnum, IGame } from '../DataProvider/Models/IGame';
import { IMatch, MapIcons, Maps, MatchStatusEnum } from '../DataProvider/Models/IMatch';
import { IPlayer } from '../DataProvider/Models/IPlayer';
import Scoreboard from '../Public/Pages/GamePage/Components/Scoreboard/Scoreboard';
import './DisplayPage.scss';

export default function DisplayPage() {
	let { id, did } = useParams();

	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();
	const matchDataProvider: IMatchDataProvider = new MatchApiDataProvider();

	const [game, setGame] = React.useState<IGame>();
	const [player, setPlayer] = React.useState<IPlayer[]>();
	const [matches, setMatches] = React.useState<IMatch[]>();
	const [loading, setLoading] = React.useState({
		loadGameData: true,
		loadPlayerData: true,
		loadMatches: true,
	});

	React.useEffect(() => {
		loadGame();
		loadPlayer();
		loadMatches();

		const interval = setInterval(() => {
			onRefresh();
		}, 5000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadGameData: true,
			}));
		gameDataProvider.getGame(id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function loadPlayer(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadPlayerData: true,
			}));
		gameDataProvider.getPlayer(id!).then((value) => {
			setPlayer(value);
			setLoading((prevState) => ({
				...prevState,
				loadPlayerData: false,
			}));
		});
	}

	function loadMatches(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadMatches: true,
			}));
		matchDataProvider.getMatch(id!).then((value) => {
			setMatches(value);
			setLoading((prevState) => ({
				...prevState,
				loadMatches: false,
			}));
		});
	}

	function onRefresh(silent: boolean = true) {
		loadPlayer(silent);
		loadMatches(silent);
		loadGame(silent);
	}

	const className = 'col-md-' + 12 / game?.playerRound!;
	const selectedMatch: IMatch | undefined = matches?.find((x) => (x.status === MatchStatusEnum.Running || x.status === MatchStatusEnum.Preparing) && x.console.toString() === did!);
	const qrCodeUrl = window.location.origin + '/Game/' + id!;

	var ordering: any = {}, // map for efficient lookup of sortIndex
		sortOrder = [MatchStatusEnum.Running, MatchStatusEnum.Preparing, MatchStatusEnum.NotStarted, MatchStatusEnum.Ended];
	for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

	const QrCodeComponent = () => {
		return (
			<div className='qrCodeInGame'>
				<QRCode value={qrCodeUrl} bgColor='transparent' fgColor='#FFFFFF' />
				<br />
				<h2 className='display-5 text-white'>Scoreboard!</h2>
			</div>
		);
	};

	return !loading.loadGameData && !loading.loadPlayerData ? (
		game?.status === GameStatusEnum.Configuration ? (
			<div className='page-wrapper'>
				<div className='center'>
					<Logo className='logo' />
					<span className='small-divider'></span>
					<h2 className='display-6 text-white'>Das Spiel wird gerade vorbereitet.</h2>
					<br /> <br />
					{game?.publicRegistration && (
						<>
							<QRCode value={qrCodeUrl} bgColor='transparent' fgColor='#FFFFFF' />
							<h2 className='display-6 text-white fst-italic'>Jetzt anmelden!</h2>
						</>
					)}
				</div>
			</div>
		) : selectedMatch ? (
			<div className='page-wrapper'>
				<QrCodeComponent />
				<div className='center' style={{ width: '90%' }}>
					<Logo className='logo' />
					<span className='small-divider'></span>
					<h2 className='display-6 text-white'>Konsole - {did!}</h2>
					{selectedMatch.status === MatchStatusEnum.Preparing && (
						<h2 className='display-6 text-white'>
							<span className='badge rounded-pill bg-warning'>Vorbereitung</span>
						</h2>
					)}
					{selectedMatch.status === MatchStatusEnum.Running && (
						<h2 className='display-6 text-white'>
							<span className='badge rounded-pill bg-success'>Läuft</span>
						</h2>
					)}
					<br />
					<div className='col-md-4 m-auto'>
						<div className='row align-items-center'>
							<div className='col-md-6 float-end'>
								<img className='float-end' src={MapIcons[selectedMatch.map]} alt='' />
							</div>
							<div className='col-md-6 text-start'>
								<h2 className='display-6 text-white fw-bolder'>{Maps[selectedMatch.map]}</h2>
							</div>
						</div>
					</div>
					<br /> <br />
					<div className='row'>
						{selectedMatch.player.map((player, i) => {
							return (
								<div className={className} style={{ textAlign: 'center' }}>
									<h2 className='display-5 text-white'>{player.name}</h2>
									<h2 className='display-5 text-white'>
										<FontAwesomeIcon icon={faGamepad} /> {i + 1}
									</h2>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		) : (
			<div className='page-wrapper'>
				<QrCodeComponent />
				<div className='container'>
					<div className='center'>
						<Logo className='logo' />
						<br /> <br />
						<Scoreboard player={player!} inverted />
						<br />
					</div>
				</div>
			</div>
		)
	) : (
		<>Loading ...</>
	);
}
