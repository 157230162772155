import React, { useEffect } from 'react';
import { GameApiDataProvider } from '../../../../DataProvider/GameDataProvider/GameApiDataProvider';
import { IGameDataProvider } from '../../../../DataProvider/GameDataProvider/IGameDataProvider';
import { IMatchDataProvider } from '../../../../DataProvider/MatchDataProvider/IMatchDataProvider';
import { MatchApiDataProvider } from '../../../../DataProvider/MatchDataProvider/MatchApiDataProvider';
import { IGame } from '../../../../DataProvider/Models/IGame';
import { IMatch, MatchStatusEnum } from '../../../../DataProvider/Models/IMatch';
import { IPlayer } from '../../../../DataProvider/Models/IPlayer';
import PlayerBox from '../Configuration/Components/PlayerBox';
import '../GamePage.scss';
import MatchBox from './Components/MatchBox';

interface IRunningCompProps {
	id: string;
}

export default function RunningComponent(props: IRunningCompProps) {
	const gameDataProvider: IGameDataProvider = new GameApiDataProvider();
	const matchDataProvider: IMatchDataProvider = new MatchApiDataProvider();

	const [game, setGame] = React.useState<IGame>();
	const [player, setPlayer] = React.useState<IPlayer[]>();
	const [matches, setMatches] = React.useState<IMatch[]>([]);
	const [loading, setLoading] = React.useState({
		loadGameData: true,
		loadPlayer: true,
		loadMatches: true,
	});

	useEffect(() => {
		loadGame();
		loadPlayer();
		loadMatches();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function loadGame() {
		setLoading((prevState) => ({
			...prevState,
			loadGameData: true,
		}));
		gameDataProvider.getGame(props.id!).then((value) => {
			setGame(value);
			setLoading((prevState) => ({
				...prevState,
				loadGameData: false,
			}));
		});
	}

	function loadPlayer(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadPlayer: true,
			}));
		gameDataProvider.getPlayer(props.id!).then((value) => {
			setPlayer(value);
			setLoading((prevState) => ({
				...prevState,
				loadPlayer: false,
			}));
		});
	}

	function loadMatches(silent: boolean = false) {
		if (!silent)
			setLoading((prevState) => ({
				...prevState,
				loadMatches: true,
			}));
		matchDataProvider.getMatch(props.id!).then((value) => {
			setMatches(value);
			setLoading((prevState) => ({
				...prevState,
				loadMatches: false,
			}));
		});
	}

	function onRefresh(silent: boolean = true) {
		loadPlayer(silent);
		loadMatches(silent);
	}

	const className = 'col-md-' + 12 / game?.consoleCount!;

	return (
		<>
			{!loading.loadGameData && !loading.loadMatches && !loading.loadPlayer ? (
				<div>
					<PlayerBox player={player!} gameId={props.id!} onRefresh={() => loadPlayer(true)} hideConfirm={true} hideDelete={true} hideAdd={true} />

					<div className='row'>
						{matches &&
							matches.map((x, i) => {
								return (
									<MatchBox
										player={player!}
										match={x}
										class={className}
										index={i}
										onRefresh={onRefresh}
										playerRound={game!.playerRound!}
										canPrepare={matches.filter((x) => x.status === MatchStatusEnum.Running || x.status === MatchStatusEnum.Preparing).length < game?.consoleCount!}
									/>
								);
							})}
					</div>
				</div>
			) : (
				<p>Loading ...</p>
			)}
		</>
	);
}
