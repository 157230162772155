import React from 'react';
import { useAuth } from '../../../../Auth/useAuthHook';

interface IResetPasswordComponentProps {}

export default function ResetPasswordComponent(props: IResetPasswordComponentProps) {
	const { onForgotPassword } = useAuth();

	const [username, setUsername] = React.useState<string>();
	const [loading, setLoading] = React.useState<boolean>();
	const [message, setMessage] = React.useState<string>();
	const [confirmationSent, setConfirmationSent] = React.useState<boolean>(false);

	function onChangeUsername(e: any) {
		setUsername(e.target.value);
	}

	function handleLogin(e: any) {
		e.preventDefault();

		if (!username) return;

		setLoading(true);
		setMessage('');

		onForgotPassword(username)
			.then((x: any) => {
				console.log(x);
				setLoading(false);
				setConfirmationSent(true);
			})
			.catch((error: any) => {
				setMessage(error);
				setLoading(false);
			});
	}

	return (
		<div className='card-body p-5 text-center'>
			<h3 className='mb-5'>Forgot Password</h3>
			{!confirmationSent ? (
				<>
					<form>
						<div className='form-outline mb-4'>
							<input type='text' className='form-control form-control-lg' placeholder='Username' value={username} onChange={onChangeUsername} />
						</div>

						{message && <p className='lead text-danger'>{message}</p>}

						<button className='btn btn-primary btn-lg btn-block' disabled={loading} onClick={handleLogin}>
							{loading && <span className='spinner-border spinner-border-sm'></span>}
							<span> Reset </span>
						</button>
					</form>
				</>
			) : (
				<p className='lead text-success'>We sent you a confirmation mail for resetting your password! Please check your inbox.</p>
			)}
		</div>
	);
}
