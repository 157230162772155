import React from "react";
import Logo from "../../../Assets/logo";
import "../../PublicPages.scss";

export default function HomePage() {
	return (
		<div className='page-wrapper'>
			<div className='center'>
				<Logo className='logo' />
			</div>
		</div>
	);
}
